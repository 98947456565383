import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import { Fragment, useState } from 'react'
import { Controller, Resolver, useForm } from 'react-hook-form'
import { v4 as uuid4 } from 'uuid'

import useManageServerTestCasesAndConfigs from '../../../features/ConfigureDataCollectionAdminPanelPage/useManageServerTestCasesAndConfigs'
import { generateFormDataFromRule } from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/configInputTransformations'
import type {
  RuleIn,
  WindowChangeTriggerRuleFormData,
} from '../../../features/ConfigureDataCollectionAdvancedAdminPanelPage/types'
import { Button, InputField, Text, TextArea } from '../../designs'
import { windowChangeTriggerRuleFormSchema } from '../DataCollectionForms/formSchemas'
import { Loader } from '../Loader'
import { ContentDisplay } from '../displays'
import { windowChangeTriggerFormDataToRuleIn } from './dataHelpers'

interface Props {
  appRules: RuleIn[]
  onClose: () => void
}

const AddWindowChangeTriggerFields = ({ appRules, onClose }: Props) => {
  const { createRules, isUploadingRules } = useManageServerTestCasesAndConfigs()

  const { control, handleSubmit, reset } = useForm<WindowChangeTriggerRuleFormData>({
    resolver: yupResolver(
      windowChangeTriggerRuleFormSchema,
    ) as Resolver<WindowChangeTriggerRuleFormData>,
  })

  const [selectedAppRuleIndex, setSelectedAppRuleIndex] = useState<number>(0)

  const ruleDisplayData = appRules.map((rule) => {
    if (!rule.is_dashboard_generated) {
      return <Text key={rule.id}>{JSON.stringify(rule.matching_criteria)}</Text>
    }

    const { standardFormValues } = generateFormDataFromRule(rule, [])
    return (
      <Fragment key={rule.id}>
        {standardFormValues.inUrl && (
          <Text>In URL: {standardFormValues.inUrl.map(({ value }) => value).join(', ')}</Text>
        )}
        {standardFormValues.inProcessName && (
          <Text>In Process Name: {standardFormValues.inProcessName}</Text>
        )}
        {standardFormValues.inTitle && (
          <Text>In Title: {standardFormValues.inTitle.map(({ value }) => value).join(', ')}</Text>
        )}
      </Fragment>
    )
  })

  const onCreateWindowChangeTrigger = async (formData: WindowChangeTriggerRuleFormData) => {
    const selectedAppRule = appRules[selectedAppRuleIndex]

    const ruleBase = {
      id: uuid4(),
      tags: [],
      allow_tracking: true,
      extract_identifiers: [],
      salvage_fields: [],
      reactions: [],
      matching_criteria: selectedAppRule.matching_criteria,
      web_extension_matching_criteria: selectedAppRule.web_extension_matching_criteria,
      is_dashboard_generated: selectedAppRule.is_dashboard_generated,
      dashboard_context: selectedAppRule.dashboard_context,
    } as RuleIn

    const ruleForTheTrigger = windowChangeTriggerFormDataToRuleIn(formData, ruleBase)
    await createRules([ruleForTheTrigger])
    reset()
    onClose()
  }

  if (isUploadingRules) return <Loader />

  return (
    <ContentDisplay className='mb-3 p-4'>
      <div className='space-y-3'>
        <Text>Select App Rule To Associate The UI Rule with</Text>
        <div className='flex flex-wrap gap-4'>
          {ruleDisplayData.map((rule, i) => (
            <div
              key={rule.key}
              className={clsx(
                'cursor-pointer space-y-2 rounded-md border-2 p-4',
                i === selectedAppRuleIndex
                  ? 'border-blue-500 bg-blue-300'
                  : 'border-gray-400 bg-gray-200',
              )}
              onClick={() => setSelectedAppRuleIndex(i)}
            >
              {rule}
            </div>
          ))}
        </div>

        <form className='mt-1 space-y-3' onSubmit={handleSubmit(onCreateWindowChangeTrigger)}>
          <Controller
            name='triggerName'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <InputField
                size='xs'
                label='Trigger Name'
                autoComplete='off'
                error={error?.message}
                {...field}
              />
            )}
          />

          <Controller
            name='reaction'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextArea
                rows={10}
                label='Reaction Pattern'
                autoComplete='off'
                error={error?.message}
                {...field}
              />
            )}
          />

          <Button type='submit'>Save</Button>
        </form>
      </div>
    </ContentDisplay>
  )
}

export default AddWindowChangeTriggerFields
