import * as yup from 'yup'

import { applicationCategories } from '../../types/dataCollectionsConfigs'
import { isRawPatternType } from '../../types/typeGuards'

export const appMetaFormSchema = yup.object({
  // APPLICATION COMMON INFO
  appName: yup.string().required().label('Application name'),
  category: yup.string().oneOf(Object.values(applicationCategories)).required().label('Category'),
  isPdfApplication: yup.boolean().required().default(false),
})

export const dataCollectionGranularityFormSchema = yup.object({
  isTitleCaptured: yup.boolean().required().default(false),
  isUrlCaptured: yup.boolean().required().default(false),
  isTypingCaptured: yup.boolean().required().default(false),
  isClipboardContentCaptured: yup.boolean().required().default(false),
  isKeyboardShortcutsCaptured: yup.boolean().required().default(false),
  isCopyPasteElementsCaptured: yup.boolean().required().default(false),
})

export const testCaseFormSchema = yup.object({
  // TEST CASE DATA
  processName: yup
    .string()
    .label('Process name')
    .test('urlOrProcessNameReqired', 'URL or process name is required', function (currentValue) {
      return this.parent.url || Boolean(currentValue)
    }),
  url: yup
    .string()
    .label('URL')
    .test('urlOrProcessNameReqired', 'URL or process name is required', function (currentValue) {
      return this.parent.processName || Boolean(currentValue)
    })
    .test(
      'no-http-https',
      'Remove http:// or https:// part from the URL',
      (value) => !/^(http:\/\/|https:\/\/)/.test(value ?? ''),
    ),
  title: yup.string().label('Title'),

  uiFieldKey: yup.string().nullable(),
  uiFieldValue: yup.string().nullable(),
})

function testAtleastOneValue(this: yup.TestContext<yup.AnyObject>) {
  return (
    this.parent.inProcessName ||
    (this.parent.inUrl ?? []).filter(({ value }: { value: string }) => value).length ||
    (this.parent.inTitle ?? []).filter(({ value }: { value: string }) => value).length
  )
}

export const manualRuleForm = yup.object({
  inProcessName: yup
    .string()
    .test(
      'atleastOnValueNeeded',
      'Atleast one URL, Process or Title keyword required',
      testAtleastOneValue,
    ),
  inUrl: yup.array().of(
    yup.object({
      value: yup
        .string()
        .test(
          'no-http-https',
          'Remove http:// or https:// part from the URL',
          (value) => !/^(http:\/\/|https:\/\/)/.test(value ?? ''),
        ),
    }),
  ),
  inTitle: yup.array().of(
    yup.object({
      value: yup.string(),
    }),
  ),

  processName: yup
    .string()
    .test(
      'processDataCollectionReuired',
      'Identifier Collection Rule Is Required',
      function (currentValue) {
        return (
          !currentValue ||
          this.parent.processIdPathBefore ||
          this.parent.processIdParamKey ||
          this.parent.processIdTitleBefore
        )
      },
    ),
  processIdPathBefore: yup
    .string()
    .test('processNameRequired', 'Process Name Is Required', function (currentValue) {
      return !currentValue || this.parent.processName
    }),
  processIdParamKey: yup
    .string()
    .test('processNameRequired', 'Process Name Is Required', function (currentValue) {
      return !currentValue || this.parent.processName
    }),
  processIdTitleBefore: yup
    .string()
    .test('processNameRequired', 'Process Name Is Required', function (currentValue) {
      return !currentValue || this.parent.processName
    }),
})

export const windowChangeTriggerRuleFormSchema = yup.object({
  triggerName: yup.string().required().max(255),
  reaction: yup
    .string()
    .required()
    .test(
      'reactionIsValidRawPattern',
      'Reaction Needs to Be a Valid Pattern',
      function (currentValue) {
        try {
          const data = JSON.parse(currentValue)
          return isRawPatternType(data)
        } catch {
          return false
        }
      },
    ),
})
